// Generated by ReScript, PLEASE EDIT WITH CARE

import * as URLs from "../../URLs.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Level from "../../tree/Level.js";
import * as Query from "../../Query.js";
import * as React from "../../../libs/rescript-mithril/src/React.js";
import * as Luxon from "luxon";
import * as Feather from "../../common/views/Feather.js";
import * as Mithril from "../../../libs/rescript-mithril/src/Mithril.js";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as ReactDOM from "../../../libs/rescript-mithril/src/ReactDOM.js";
import * as TileLink from "../../common/views/TileLink.js";
import * as TreeView from "../../tree/views/TreeView.js";
import * as VesselId from "../../vessel/VesselId.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as PageAppView from "../../common/views/PageAppView.js";
import * as R4SChecklist from "../R4SChecklist.js";

function update(vnode) {
  var vesselId = VesselId.Route.param("vesselId");
  var newrecord = Caml_obj.obj_dup(Query.makeRead());
  Prelude.thenDo(Query.read((newrecord.r4schecklists = R4SChecklist.ofVessel(vesselId), newrecord)), (function (param) {
          var r4schecklists = param.r4schecklists;
          var level_ids = r4schecklists.map(function (c) {
                return c.level_id;
              });
          var newrecord = Caml_obj.obj_dup(Query.makeRead());
          Prelude.thenDo(Query.read((newrecord.levels = {
                      TAG: "In",
                      _0: level_ids
                    }, newrecord)), (function (param) {
                  Mithril.setState(vnode, {
                        TAG: "Loaded",
                        checklists: r4schecklists,
                        levels: param.levels
                      });
                }));
        }));
}

function make() {
  return Mithril.view(Mithril.oninit(Mithril.component("NotReady"), (function (vnode) {
                    update(vnode);
                  })), (function (vnode) {
                var match = vnode.state.data;
                if (typeof match !== "object") {
                  return React.jsx(PageAppView.make, {
                              children: Mithril.empty,
                              headerContent: {
                                NAME: "Title",
                                VAL: "Port checklists"
                              }
                            });
                }
                var levels = match.levels;
                var checklists = match.checklists;
                return React.jsx(PageAppView.make, {
                            children: ReactDOM.jsx("div", {
                                  children: Prelude.$$Array.isNotEmpty(checklists) ? Prelude.$$Array.sort(checklists, (function (c) {
                                              return c.eta;
                                            }), true).map(function (checklist) {
                                          var url = URLs.r4sCheckListUrl(checklist.id);
                                          var level = Prelude.default(Prelude.$$Array.first(Curry._2(Prelude.$$Array.keep, levels, (function (l) {
                                                          return Caml_obj.equal(l.id, checklist.level_id);
                                                        }))), Level.Defaults.$$null);
                                          var eta = Luxon.DateTime.fromJSDate(checklist.eta).setZone("UTC").setLocale("en").toLocaleString(Luxon.DateTime.DATE_HUGE);
                                          return React.jsxKeyed(TileLink.make, {
                                                      title: R4SChecklist.getName(checklist),
                                                      href: url,
                                                      subtitle: "ETA: " + eta,
                                                      action: Caml_option.some(React.jsx(TreeView.Completion.make, {
                                                                level: level,
                                                                useColor: true
                                                              }))
                                                    }, checklist.id, undefined);
                                        }) : ReactDOM.jsxs("div", {
                                          children: [
                                            React.jsx(Feather.make, {
                                                  icon: "alert_triangle",
                                                  size: 32
                                                }),
                                            ReactDOM.jsx("br", {}),
                                            "No checklists have been generated for this vessel. Please contact your TSI."
                                          ],
                                          className: "text-gray tw-text-center tw-mt-[200px] tw-w-3/4 tw-mx-auto tw-text-lg tw-h-[100px]"
                                        }),
                                  className: "sm:tw-w-10/12 tw-w-full tw-mx-auto tw-px-2"
                                }),
                            headerContent: {
                              NAME: "Title",
                              VAL: "Port checklists"
                            },
                            headerNav: "all"
                          });
              }));
}

export {
  update ,
  make ,
}
/* URLs Not a pure module */
